<template>
    <modal ref="modalAnularPago" titulo="Anular Pago" icon="close" centrar-icon no-aceptar adicional="Anular" @adicional="anularPagoComision">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-12">
                    <p class="text-general text-center my-3 f-15">
                        Justifica la anulación
                    </p>
                </div>
                <div class="col-10">
                    <ValidationProvider v-slot="{errors}" rules="required|max:500" name="justificacion">
                        <el-input v-model="textarea" type="textarea" :rows="6" placeholder="Justificación" class="w-100" maxlength="500" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Comisiones from "~/services/comisiones";
export default {
    data(){
        return {
            textarea: '',
            id: null
        }
    },
    methods: {
        toggle(id){
            this.id = id
            this.$refs.modalAnularPago.toggle();
        },
        async anularPagoComision(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }

                let params = {
                    anulacion: this.textarea,
                    id: this.id
                }

                const {data} = await Comisiones.anularPagoComision(params)
                this.notificacion('Mensaje', data.error ?? data.mensaje, data.error ? 'warning' : 'success')
                if(!data.error){
                    this.$emit('listar')
                    this.$refs.modalAnularPago.toggle()
                }

            } catch (e){
                this.error_catch(e)
            }
        },
    }

}
</script>

<style>

</style>
